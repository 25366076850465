import type { Tracker } from "@app/tracking/tracker";

import {
  clickedConnect,
  clickedMenu,
  clickedMenuLogout,
  clickedMenuProfile,
  clickedMenuTickets
} from "../../tracking/header-tracking";
import {
  PageSection,
  TargetPage,
  clickLink
} from "../../tracking/link-tracking";

const Header = {
  init: function (tracker: Tracker) {
    const signin_link = document.getElementById("js-header-signin-link");
    const account_link = document.getElementById("js-header-dropdown-link");
    const tickets_link = document.getElementById("js-menu-tickets-link");
    const profile_link = document.getElementById("js-menu-profile-link");
    const logout_link = document.getElementById("js-menu-logout-link");
    const helpcenter_link = document.querySelector<HTMLElement>(
      '[data-id="header-helpcenter-link"]'
    );

    if (signin_link) {
      signin_link.onclick = e => {
        tracker.trackLink(clickedConnect(), e, signin_link);
      };
    }

    if (account_link) {
      account_link.onclick = e => {
        tracker.trackLink(clickedMenu(), e, account_link);
      };
    }

    if (tickets_link) {
      tickets_link.onclick = e => {
        tracker.trackLink(clickedMenuTickets(), e, tickets_link);
      };
    }

    if (profile_link) {
      profile_link.onclick = e => {
        tracker.trackLink(clickedMenuProfile(), e, profile_link);
      };
    }

    if (logout_link) {
      logout_link.onclick = e => {
        tracker.trackLink(clickedMenuLogout(), e, logout_link);
      };
    }

    if (helpcenter_link) {
      helpcenter_link.addEventListener("click", e => {
        tracker.trackLink(
          clickLink(PageSection.HEADER, TargetPage.HELP_CENTER),
          e,
          helpcenter_link
        );
      });
    }
  }
};

export default Header;
