import {
  configureStore as reduxConfigureStore,
  combineReducers
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import searchFormReducer from "@app/modules/search/store/reducer";
import { modalsMiddleware } from "@app/store/middlewares/modals";
import { reducers as rootReducer } from "@app/store/reducers";

export default function configureStore<T extends {}>(INITIAL_STATE: T) {
  return reduxConfigureStore({
    reducer: combineReducers({
      ...rootReducer,
      ...searchFormReducer
    }),
    middleware: [thunk, modalsMiddleware] as const,
    preloadedState: INITIAL_STATE
  });
}
