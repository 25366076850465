import { AmplitudeEvent } from "@app/types/tracking/common";
import {
  ClickedReadLessReviews,
  ClickedReadMoreReviews
} from "@app/types/tracking/reviews";

import { TrackingAction } from "./constants/tracking-action";

export function clickedReadMoreReviews(): AmplitudeEvent<ClickedReadMoreReviews> {
  return {
    action: TrackingAction.ClickedReadMoreReviews,
    details: {}
  };
}

export function clickedReadLessReviews(): AmplitudeEvent<ClickedReadLessReviews> {
  return {
    action: TrackingAction.ClickedReadLessReviews,
    details: {}
  };
}
