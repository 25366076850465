import { SearchFormVariant } from "@app/components/search-form/search-form";
import { SearchPlace } from "@app/types/search-types";

export const US_OR_CANADA_ISO2 = ["US", "CA"];

export function getPlaceName(place: SearchPlace | null) {
  if (place && place.location) {
    return place.location.full_name.split(",")[0];
  }
  if (place && place.city.full_name) {
    return place.city.full_name.split(",")[0];
  }
  return "";
}

const isUSorCanada = (country_code2: string) => {
  return US_OR_CANADA_ISO2.includes(country_code2);
};

export const getSearchQueryValue = (place: PartialSuggestion | null) => {
  if (!place) {
    return "";
  }

  return place.location
    ? place.location.full_name
    : place.full_name.split(",")[0];
};

export interface PartialSuggestion {
  country?: string;
  country_code2: string;
  full_name: string;
  region_code?: string | null;
  location?: {
    country?: string;
    country_code2?: string;
    full_name: string;
    region_code?: string | null;
  };
}

const discardEmptyStrings = (items: Array<string | undefined>): string => {
  return items.filter(item => !!item).join(", ");
};

export const getFormattedSuggestionName = (
  place_suggestion: PartialSuggestion | null
) => {
  if (!place_suggestion) {
    return "";
  }

  // Full name returns localized names by the user locale
  const [localized_city, localized_state] =
    place_suggestion.full_name.split(", ");

  const is_us_or_canada = isUSorCanada(place_suggestion.country_code2);

  if (is_us_or_canada && !place_suggestion.location) {
    if (place_suggestion.region_code) {
      return discardEmptyStrings([
        localized_city,
        place_suggestion.region_code
      ]);
    }
  }

  const display_country = is_us_or_canada
    ? place_suggestion.country_code2
    : place_suggestion.country;
  if (place_suggestion.location) {
    if (place_suggestion.location.full_name === localized_city) {
      return discardEmptyStrings([
        place_suggestion.location.full_name,
        display_country
      ]);
    }

    return discardEmptyStrings([
      place_suggestion.location.full_name,
      localized_city,
      display_country
    ]);
  }

  if (localized_city === localized_state) {
    return discardEmptyStrings([localized_city, display_country]);
  }

  return place_suggestion.full_name;
};

export type CompactConfiguration = Partial<
  Record<"xs" | "sm" | "md" | "lg" | "xl", boolean>
>;

export function getCompactConfiguration(
  variant: SearchFormVariant
): CompactConfiguration {
  switch (variant) {
    case "results":
      return { xs: true, sm: true, md: true, lg: true, xl: true };
    case "results-menu":
      return { xs: true, xl: false };
    case "landing-page":
    default:
      return { xs: false, sm: false, md: false, lg: false, xl: false };
  }
}
