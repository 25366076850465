import { useAppContext } from "@app/helpers/hooks";
import { PageEntityTypes } from "@app/lib/page-entity";
import { RequestEntity } from "@app/types/app-types";

import { landing_pages_entities_type } from "@data/entity-types";

const landing_entities: Array<PageEntityTypes> = Object.values(
  landing_pages_entities_type
);

export const useEntity = () => {
  const { entity } = useAppContext();
  return entity;
};

export function isLandingPage(
  entity: PageEntityTypes | RequestEntity | null | undefined
): boolean {
  if (!entity) {
    return false;
  }
  if (typeof entity === "string") {
    return landing_entities.includes(entity);
  }
  return landing_entities.includes(entity.type);
}

export function isCheckoutPage(
  entity: PageEntityTypes | RequestEntity | undefined
): boolean {
  if (!entity) {
    return false;
  }
  if (typeof entity === "string") {
    return entity === "checkout";
  }
  return entity.type === "checkout";
}

export const useIsLandingPage = () => {
  const entity = useEntity();
  return isLandingPage(entity);
};
