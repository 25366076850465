/* eslint-disable no-use-context/no-use-context */
import { Dispatch, useContext } from "react";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { useTheme } from "@busbud/design-system-components/dist/styles/useTheme";
import { BreakpointKeys } from "@busbud/design-system-components/dist/types/tokens/breakpoints";
import { useMediaQuery } from "@busbud/design-system-components/dist/utilities/useMediaQuery";

import AppContext, {
  AppCommonContext,
  ApplicationContext
} from "@app/context/app-context";
import { LiteAppContext } from "@app/context/lite-app-context";

/** Common shared context available to both client and server */
export const useAppContext = (): AppCommonContext => useContext(AppContext);

/** Lightweight shared context available to both client and server for search and landing page entries */
export const useLiteAppContext = (): LiteAppContext =>
  useContext(LiteAppContext);

/** Context available during client-side rendering and hydration */
export const useClientAppContext = () => useAppContext() as ApplicationContext;

const useIsViewport = (key: BreakpointKeys) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key), {
    noSsr: true // Needed otherwise it is rendered twice, first time with false, the value of the server, and a second time with the CSR resolved value
  });
};
export const useIsXsViewport = () => useIsViewport("xs");
export const useIsSmViewport = () => useIsViewport("sm");
export const useIsMdViewport = () => useIsViewport("md");

export type appDispatch = Dispatch<AnyAction> &
  ThunkDispatch<any, undefined, AnyAction>;
export const useAppDispatch: () => appDispatch = useDispatch;
