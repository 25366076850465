import { Choices } from "@busbud/int-schemas/lib";

export const FEMALE = "female";
export const MALE = "male";
export const ANY = "any";

export const ADULT = "adult";
export const CHILD = "child";
export const YOUTH = "youth";
export const SENIOR = "senior";

export const PASSENGER_TYPES = [ADULT, CHILD, SENIOR] as const;
export type PassengerType = (typeof PASSENGER_TYPES)[number];

export const UDMGender = {
  Male: Choices.Gender.Male,
  Female: Choices.Gender.Female
} as const;

export type PassengerGender = "male" | "female" | "other";

export const MAX_PASSENGERS_NUMBER = 5;
