import { button_links_class } from "@app/components/button-link/button-link-constants";

function openLink(link: Element) {
  const url = link.getAttribute("data-href");
  if (!url) {
    return;
  }

  const open_in_new_tab = link.hasAttribute("data-new-tab");
  if (open_in_new_tab) {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
}

function trackLinkClick(link: Element) {
  const action = link.getAttribute("data-tracking-action");
  if (!action) {
    return;
  }

  window.tracker.track({
    action: action,
    details: link.getAttribute("data-tracking-details") ?? "{}"
  });
}

export function initializeButtonLinks() {
  if (typeof window === "undefined") {
    throw new Error(
      `This function ${this.name} can only be called in the frontend`
    );
  }
  const links = document.querySelectorAll(`.${button_links_class}`);

  links.forEach(link => {
    addClickListener(link);
  });
}

export function addClickListener(link: Element) {
  link.addEventListener("click", () => {
    openLink(link);
    trackLinkClick(link);
  });
}
