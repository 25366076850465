import { PayloadAction } from "@reduxjs/toolkit";

import { PassengerComplete } from "@app/types/search-types";

import { isValidAge } from "../../../selectors/passengers";
import {
  ADULT,
  SENIOR,
  PassengersCount,
  ADULT_STUDENT_CHECKBOXES,
  ADULT_WHEELCHAIR_CHECKBOXES,
  ADULT_WHEELCHAIR,
  ADULT_STUDENT,
  AGES_FIELDS,
  STUDENT,
  WHEELCHAIR
} from "../passengers-constants";

export const removePassengerReducer = (
  state: PassengerComplete,
  action: PayloadAction<{
    category: PassengersCount;
  }>
) => {
  const { category } = action.payload;

  if (state[category] === 0) {
    return state;
  }

  const next_state = { ...state };

  if (category === ADULT) {
    // At least one adult/senior
    if (state[ADULT] <= 1 && state[SENIOR] === 0) {
      return state;
    }

    next_state[ADULT] = state[ADULT] - 1;

    next_state[ADULT_STUDENT_CHECKBOXES] = state[
      ADULT_STUDENT_CHECKBOXES
    ].slice(0, -1);
    next_state[ADULT_WHEELCHAIR_CHECKBOXES] = state[
      ADULT_WHEELCHAIR_CHECKBOXES
    ].slice(0, -1);

    next_state[ADULT_WHEELCHAIR] = next_state[
      ADULT_WHEELCHAIR_CHECKBOXES
    ].reduce((prev, curr) => {
      return prev + (curr === -1 ? 0 : 1);
    }, 0);

    next_state[ADULT_STUDENT] = next_state[ADULT_STUDENT_CHECKBOXES].reduce(
      (prev, curr) => {
        return prev + (curr === -1 ? 0 : 1);
      },
      0
    );

    return next_state;
  }
  const [pax_student_count, pax_ages, pax_student_ages] =
    AGES_FIELDS[category][STUDENT];
  const [pax_wheelchair_count, , pax_wheelchair_ages] =
    AGES_FIELDS[category][WHEELCHAIR];

  next_state[category] = state[category] - 1;

  next_state[pax_ages] = state[pax_ages].slice(0, next_state[category]);
  next_state[pax_student_ages] = state[pax_student_ages].slice(
    0,
    next_state[category]
  );

  next_state[pax_student_count] =
    next_state[pax_student_ages].filter(isValidAge).length;

  next_state[pax_wheelchair_ages] = state[pax_wheelchair_ages].slice(
    0,
    next_state[category]
  );
  next_state[pax_wheelchair_count] =
    next_state[pax_wheelchair_ages].filter(isValidAge).length;

  return next_state;
};
