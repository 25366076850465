import type { Tracker } from "@app/tracking/tracker";
import { TrackingEvent } from "@app/types/tracking";

import { DropdownChip } from "./dropdown-chip";
import {
  PageSection,
  openDropdown,
  selectDropdownItem
} from "../../../tracking/dropdown-tracking";

const TRACKED_ID_LIST = ["currency-picker", "language-picker", "account-menu"];
const PAGE_SECTION = PageSection.Header;

function HeaderDropdowns(
  window: Pick<Window, "document" | "tracker"> = global.window
) {
  if (!window || !window.document) {
    return;
  }
  const document = window.document;

  const all_dropdowns = document.querySelectorAll(".header-dropdown");
  all_dropdowns.forEach(dropdown => {
    new HeaderDropdown(dropdown, { tracker: window.tracker });
    if (dropdown.getAttribute("id")?.includes(DropdownChip.dropdown_base_id)) {
      new DropdownChip(dropdown);
    }
  });
}

interface HeaderDropdownOptions {
  tracker?: Tracker;
  context?: {
    addEventListener: Document["addEventListener"];
    removeEventListener: Document["removeEventListener"];
  };
}

export class HeaderDropdown {
  private tracker: Tracker | undefined;
  private button: HTMLElement | null;
  private content: HTMLElement | null;
  private tracking_enabled: boolean;
  private context: {
    addEventListener: Document["addEventListener"];
    removeEventListener: Document["removeEventListener"];
  };
  private dropdown_id: string | undefined;
  private is_open: boolean;

  public constructor(container: Element, options: HeaderDropdownOptions = {}) {
    this.context = options.context || container.ownerDocument;
    this.tracker = options.tracker;
    this.button = container.querySelector(".header-dropdown-link");
    this.content = container.querySelector(".js-header-dropdown");

    this.dropdown_id =
      (this.content?.parentNode as Element | null)?.getAttribute("id") ??
      undefined;
    this.tracking_enabled =
      this.dropdown_id !== undefined &&
      TRACKED_ID_LIST.includes(this.dropdown_id);

    this.is_open = false;

    this.closeIfClickOutside = this.closeIfClickOutside.bind(this);
    this.addEventListenerAsync = this.addEventListenerAsync.bind(this);
    this.closeIfEscapeKeyPressed = this.closeIfEscapeKeyPressed.bind(this);

    this.attachButtonListeners();
    this.attachContentListeners();
    this.attachEscapeKeyListener();
  }

  public attachContentListeners(): void {
    this.content?.addEventListener("click", e => {
      if (this.dropdown_id) {
        this.track(
          selectDropdownItem(this.dropdown_id, PAGE_SECTION, {
            clicked_section: (e.target as Element | null)?.id
          })
        );
      }
    });
  }

  public attachButtonListeners(): void {
    if (!this.button) {
      return;
    }
    const { classList } = this.button;
    if (classList.contains("header-dropdown-link")) {
      this.button.addEventListener("click", this.toggle.bind(this));
    }
  }

  public attachEscapeKeyListener(): void {
    this.context.addEventListener("keydown", this.closeIfEscapeKeyPressed);
  }

  public detachEscapeKeyListener(): void {
    this.context.removeEventListener("keydown", this.closeIfEscapeKeyPressed);
  }

  public closeIfEscapeKeyPressed(event: KeyboardEvent): void {
    if (event.key === "Escape" && this.is_open) {
      this.close();
    }
  }

  public toggle(): void {
    this.is_open ? this.close() : this.open();
  }

  public async open(): Promise<void> {
    this.addEventListenerAsync(this.closeIfClickOutside);
    this.is_open = true;
    if (this.content) {
      this.content.removeAttribute("hidden");
      // @ts-expect-error There's no need for additional `this.content` check.
      setTimeout(() => this.content.classList.add("open"), 50);
    }

    if (this.dropdown_id) {
      this.track(openDropdown(this.dropdown_id, PAGE_SECTION));
    }

    this.attachEscapeKeyListener();
  }

  public close(): void {
    this.context.removeEventListener("click", this.closeIfClickOutside);
    this.detachEscapeKeyListener();
    this.is_open = false;
    if (this.content) {
      this.content.classList.remove("open");
      this.content.addEventListener(
        "transitionend",
        () => {
          // @ts-expect-error There's no need for additional `this.content` check.
          this.content.setAttribute("hidden", "");
        },
        { once: true }
      );
    }
  }

  public closeIfClickOutside(e: MouseEvent): void {
    // This dropdown implementation will close even when a click happens within
    // the content. If you want to keep the dropdown open, use event.stopPropagation
    // in your content elements (or event.nativeEvent.stopImmediatePropagation if it's
    // a React synthetic event)
    const is_inside = this.button?.contains(e.target as Node | null);

    if (!is_inside) {
      this.close();
    }
  }

  /*
   * This is added asynchronously as we want the click event
   * to propagate up the DOM before adding the event listener
   */
  public addEventListenerAsync(callback: (e: MouseEvent) => void): void {
    setTimeout(() => this.context.addEventListener("click", callback), 0);
  }

  public track(event: TrackingEvent) {
    if (this.tracking_enabled && this.tracker) {
      this.tracker.track(event);
    }
  }
}

export default HeaderDropdowns;
