const Activity = {
  init: function () {
    this.count_badge = window.document.querySelector(
      ".js-header-activity-badge"
    );

    if (!this.count_badge) {
      return;
    }

    this.count_url = this.count_badge.getAttribute("data-count-url");

    if (!this.count_url) {
      return;
    }

    fetch(this.count_url, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json() as Promise<{ count: number }>)
      .then(response => {
        const count = response.count;

        if (count) {
          this.count_badge.innertHTML = count;
          this.count_badge.style.display = "block";
        } else {
          this.count_badge.innertHTML = "";
          this.count_badge.style.display = "none";
        }
      });
  }
};

export default Activity;
