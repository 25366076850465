type TriggerName = "autocomplete" | "async_cart";

/**
 * Triggers a Hotjar event that can be used to start recordings or other features on Hotjar.
 * Check the documentation for more info on how to setup:
 * https://help.hotjar.com/hc/en-us/articles/115015712548-How-to-Use-JavaScript-Triggers-to-Start-Recordings
 *
 * @param event_name
 * @param other_attributes
 */
function trigger(
  event_name: TriggerName,
  other_attributes: Record<string, unknown> = {}
) {
  if (!window || typeof window.hj !== "function") {
    return;
  }
  /* eslint-disable */
  try {
    const attributes = other_attributes;
    if (window.amplitude) {
      attributes.amplitude_session_id = window.amplitude.getSessionId();
    }

    if (window.BB && window.BB.user_country_code) {
      attributes.user_country_code = window.BB.user_country_code;
    }

    if (Object.keys(attributes).length > 0) {
      // @ts-expect-error
      window.hj("identify", null, attributes);
    }

    window.hj("event", event_name);
  } catch {}
  /* eslint-enable */
}

export default { trigger };
