import { createContext } from "react";

import BusbudTerms from "@busbud/busbud-terms";

import { HTTPClient } from "@app/lib/http-clients/http-client-types";
import { PageEntity } from "@app/lib/page-entity";
import AdyenCheckoutSDKService from "@app/services/adyen-checkout-sdk";
import NapiClient from "@app/services/api";
import { DatadogClient } from "@app/services/datadog/client";
import { GeoDataService } from "@app/services/geo-data-service";
import IntlProviderService from "@app/services/intl-provider";
import { SentryClient } from "@app/services/sentry-facade";
import {
  LiteTranslatorService,
  TranslatorService
} from "@app/services/translator";
import UrlBuilderService from "@app/services/url-builders/url-builder";
import type { Tracker } from "@app/tracking/tracker";
import { DeviceInfo } from "@app/types/device";
import { Features } from "@app/types/experiments";
import { AltLocaleLinks } from "@app/types/locales";
import { DeepPartial } from "@app/types/meta-types";

import { SupportedLanguage, SupportedLocale } from "@config/locales";
import { Partner, PartnerConfig } from "@partners/partner.type";

export interface AppCommonContext {
  api: NapiClient;
  browser_locale: string;
  csrf: string;
  device: DeviceInfo;
  features: Partial<Features>;
  geoData: GeoDataService;
  httpClient: HTTPClient;
  intlProvider: IntlProviderService;
  language: SupportedLanguage; // ex: en, fr, pt
  locale: SupportedLocale; // ex: en-ca, fr-ca, pt-pt
  locale_links: AltLocaleLinks;
  sentry: SentryClient | null;
  termsBuilder: BusbudTerms;
  tracker: Tracker;
  translator: TranslatorService;
  urlBuilder: UrlBuilderService;
  user_country_code: string;
  user_region: string;
  whitelabel: Partner | undefined;
  whitelabel_settings: PartnerConfig["whitelabel_settings"] | undefined;
  entity: PageEntity | null;
  adyenCheckoutSdkService: AdyenCheckoutSDKService | undefined;
}

export type ApplicationContext<AdditionalContext = {}> = AppCommonContext &
  AdditionalContext;

export type AppServerContext = ApplicationContext<{
  datadog: DatadogClient;
  liteTranslator: LiteTranslatorService;
}>;

const AppContext = createContext({
  api: undefined,
  browser_locale: undefined,
  csrf: undefined,
  device: undefined,
  features: {},
  geoData: undefined,
  httpClient: undefined,
  intlProvider: undefined,
  language: undefined,
  locale: undefined,
  locale_links: {},
  sentry: null,
  tracker: undefined,
  translator: undefined,
  termsBuilder: undefined,
  urlBuilder: undefined,
  user_country_code: undefined,
  user_region: undefined,
  whitelabel: undefined,
  whitelabel_settings: undefined,
  entity: null,
  adyenCheckoutSdkService: undefined
} satisfies DeepPartial<ApplicationContext>) as unknown as React.Context<ApplicationContext>;

export const AppContextProvider = AppContext.Provider;
export default AppContext;
