export const ADULT = "adult";
export const CHILD = "child";
export const SENIOR = "senior";
export const ADULT_WHEELCHAIR = "adult_wheelchair";
export const CHILD_WHEELCHAIR = "child_wheelchair";
export const SENIOR_WHEELCHAIR = "senior_wheelchair";
export const CHILD_AGES = "child_ages";
export const SENIOR_AGES = "senior_ages";
export const CHILD_WHEELCHAIR_AGES = "child_wheelchair_ages";
export const SENIOR_WHEELCHAIR_AGES = "senior_wheelchair_ages";
export const ADULT_STUDENT = "adult_student";
export const CHILD_STUDENT = "child_student";
export const CHILD_STUDENT_AGES = "child_student_ages";
export const SENIOR_STUDENT = "senior_student";
export const SENIOR_STUDENT_AGES = "senior_student_ages";
export const WHEELCHAIR = "wheelchair";
export const STUDENT = "student";
export const ADULT_STUDENT_CHECKBOXES = "adult_student_checkboxes";
export const ADULT_WHEELCHAIR_CHECKBOXES = "adult_wheelchair_checkboxes";
export const AGE_RANGES = {
  child: { min: 0, max: 18 },
  senior: { min: 60, max: 80 }
};

export type PassengersCount = typeof ADULT | typeof CHILD | typeof SENIOR;
export type PassengersAges = typeof CHILD_AGES | typeof SENIOR_AGES;
export type PassengersAttributes = typeof WHEELCHAIR | typeof STUDENT;

export type PassengersWheelchairCount =
  | typeof ADULT_WHEELCHAIR
  | typeof CHILD_WHEELCHAIR
  | typeof SENIOR_WHEELCHAIR;

export type PassengersWheelchairAges =
  | typeof CHILD_WHEELCHAIR_AGES
  | typeof SENIOR_WHEELCHAIR_AGES;

export type PassengersStudentsCount =
  | typeof ADULT_STUDENT
  | typeof CHILD_STUDENT
  | typeof SENIOR_STUDENT;

export type PassengersStudentsAges =
  | typeof CHILD_STUDENT_AGES
  | typeof SENIOR_STUDENT_AGES;

export type PassengerAttributeNoAgeFields = Record<
  PassengersAttributes,
  [PassengersStudentsCount | PassengersWheelchairCount]
>;
export const NO_AGES_FIELDS: Record<
  typeof ADULT,
  PassengerAttributeNoAgeFields
> = {
  [ADULT]: {
    [STUDENT]: [ADULT_STUDENT],
    [WHEELCHAIR]: [ADULT_WHEELCHAIR]
  }
};

export type PassengerAttributeAgeFields = Record<
  PassengersAttributes,
  [
    PassengersStudentsCount | PassengersWheelchairCount,
    PassengersAges,
    PassengersStudentsAges | PassengersWheelchairAges
  ]
>;
export const AGES_FIELDS: Record<
  Exclude<PassengersCount, typeof ADULT>,
  PassengerAttributeAgeFields
> = {
  [CHILD]: {
    [STUDENT]: [CHILD_STUDENT, CHILD_AGES, CHILD_STUDENT_AGES],
    [WHEELCHAIR]: [CHILD_WHEELCHAIR, CHILD_AGES, CHILD_WHEELCHAIR_AGES]
  },
  [SENIOR]: {
    [STUDENT]: [SENIOR_STUDENT, SENIOR_AGES, SENIOR_STUDENT_AGES],
    [WHEELCHAIR]: [SENIOR_WHEELCHAIR, SENIOR_AGES, SENIOR_WHEELCHAIR_AGES]
  }
};
