import React, { useContext } from "react";

import { LiteAppContext } from "@app/context/lite-app-context";
import {
  clearPlaceholderModalTimeout,
  hidePlaceholderModal,
  showPlaceholderModal
} from "@app/modules/search/helpers/mobile-placeholder-modal";

export const PlacholderModal = () => {
  const { device } = useContext(LiteAppContext);

  const is_mobile = device?.is_mobile;

  React.useEffect(() => {
    if (is_mobile) {
      showPlaceholderModal();
    }

    return () => {
      clearPlaceholderModalTimeout();
      if (is_mobile) {
        hidePlaceholderModal();
      }
    };
  });

  return null;
};
