import { useSelector } from "react-redux";

import { PassengerType } from "@app/constants/passengers";
import { useAction } from "@app/store/hooks/useAction";

import {
  getPassengers,
  getTotalPassengersCount,
  areChildrenAccompanied,
  canRemovePassengerWithCategory,
  isMissingAges
} from "../selectors/passengers";
import { setPassengers } from "../slices/search-form/passengers";

export function usePassengers(category?: PassengerType) {
  const _setPassengers = useAction(setPassengers);

  const passengers = useSelector(getPassengers);
  const are_children_accompanied = useSelector(areChildrenAccompanied);
  const total_passengers_count = useSelector(getTotalPassengersCount);
  const can_remove_passenger = useSelector(
    canRemovePassengerWithCategory(category)
  );
  const is_missing_ages = useSelector(isMissingAges);

  return {
    passengers,
    total_passengers_count,
    are_children_accompanied,
    can_remove_passenger: can_remove_passenger,
    setPassengers: _setPassengers,
    is_missing_ages
  };
}
