import "./includes/core-vanilla";
import "./includes/activity";
import "./includes/header-desktop";

import React, { ReactNode } from "react";

import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { ModalManagerLanding } from "@app/components/modals";
import {
  LazyCalendarModal,
  LazyPassengersSelector
} from "@app/components/modals/modal-manager-landing";
import { SearchFormClient } from "@app/components/search-form/search-form-client";
import { ReactQueryProvider } from "@app/context/react-query-provider";
import { ThemeProviderClient } from "@app/context/theme-provider-client";
import { TRUSTPILOT_WIDGET_CLASS } from "@app/modules/trustpilot/components/trustpilot-constants";
import { LiteServiceProviderClient } from "@app/services/context-providers/lite-service-provider-client-base";
import hotjar from "@app/tracking/hotjar";
import { acceptHotReplacement } from "@app/utils/hmr";

import { initQuickMenuTracking } from "../components/landing-pages/quick-access-menu/quick-access-menu-client";
import { PriceCalendarBehaviour } from "../modules/price-calendar/price-calendar-behaviour";
import { Reviews } from "../modules/reviews";
import { BB, buildLandingStore } from "./setups/landing";

acceptHotReplacement(module);

if (BB.whitelabel_shared_info) {
  window.localStorage.setItem(
    `${BB.whitelabel}_shared_info`,
    JSON.stringify(BB.whitelabel_shared_info)
  );
}

if (!window.store) {
  window.store = buildLandingStore();
}

document.addEventListener("DOMContentLoaded", () => {
  // Bypass third party scripts for testing purposes
  const urlParams = new URLSearchParams(window.location.search);
  const disable_third_party_scripts = urlParams.get(
    "disable_third_party_scripts"
  );

  const passengerInput = document.getElementById("passenger-input");
  if (passengerInput) {
    function handlePreloadPassengerChunk() {
      passengerInput?.removeEventListener(
        "mouseenter",
        handlePreloadPassengerChunk
      );
      LazyPassengersSelector.preload();
    }

    // Passenger selector starts loading when the user hovers over the input, or when the page is finished loading
    passengerInput.addEventListener("mouseenter", handlePreloadPassengerChunk);
    window.addEventListener("load", handlePreloadPassengerChunk);
  }

  setTimeout(() => {
    // Loaded when the main thread is free
    LazyCalendarModal.preload();

    setTimeout(() => {
      if (disable_third_party_scripts !== "all") {
        Reviews.init(window.tracker);
        new PriceCalendarBehaviour().init(window.tracker, window.document);

        initQuickMenuTracking(window.tracker);

        const trustpilot = window.Trustpilot;
        if (trustpilot) {
          const trustboxes = document.querySelectorAll<HTMLElement>(
            `.${TRUSTPILOT_WIDGET_CLASS}`
          );
          trustboxes.forEach(trustbox => {
            trustpilot.loadFromElement(trustbox);
          });
        }

        if (BB.experiments.auto_complete_suggestions === "1") {
          try {
            hotjar.trigger("autocomplete");
          } catch (err) {}
        }

        import(
          /* webpackChunkName: "web-vitals" */ "./includes/web-vitals-reporting"
        ).then(({ WebVitalsReporting }) => {
          new WebVitalsReporting(
            window.BB.inst.request_id,
            window.BB.experiments,
            window.BB.entity,
            window.BB.config.locale,
            window.BB.device
          );
        });
      }
    }, 1);
  }, 1);
});

const root_id = "header-search";
function bootstrap(app: ReactNode) {
  const container = document.getElementById(root_id) as HTMLElement;
  hydrateRoot(container, app);
}

(function hydrateLandingPage() {
  const has_affiliate_checkbox: boolean =
    !!window.BB.features.LANDING_PAGES_SHOULD_SHOW_AFFILIATE_CHECKBOX;

  bootstrap(
    <ThemeProviderClient>
      <Provider store={window.store}>
        <LiteServiceProviderClient BB={window.BB} I18N={window.__I18N__}>
          <ReactQueryProvider>
            <SearchFormClient
              with_affiliate_checkbox={has_affiliate_checkbox}
              affiliate_checked
            />
            <ModalManagerLanding />
          </ReactQueryProvider>
        </LiteServiceProviderClient>
      </Provider>
    </ThemeProviderClient>
  );
})();
