import type { Tracker } from "@app/tracking/tracker";

import {
  clickedReadMoreReviews,
  clickedReadLessReviews
} from "../../tracking/reviews-tracking";

export const Reviews = new (class {
  private tracker: Tracker | undefined;
  private expand_link_elem: HTMLElement | null = null;

  public init(tracker: Tracker) {
    this.tracker = tracker;
    this.expand_link_elem = document.getElementById("reviews-expand-button");
    this._initReviewLinks();
  }

  private _initReviewLinks() {
    if (!this.expand_link_elem) {
      return null;
    }

    this.expand_link_elem.addEventListener("click", () => {
      const is_expanded =
        this.expand_link_elem?.attributes.getNamedItem("aria-expanded");

      if (is_expanded) {
        this.tracker?.track(clickedReadLessReviews());
      } else {
        this.tracker?.track(clickedReadMoreReviews());
      }
    });
  }
})();
