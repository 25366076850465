import { PayloadAction } from "@reduxjs/toolkit";

import { PassengerComplete } from "@app/types/search-types";

import {
  ADULT,
  AGES_FIELDS,
  NO_AGES_FIELDS,
  PassengersCount,
  PassengersAttributes
} from "../passengers-constants";

export const unassignAttributeToPassengerReducer = (
  state: PassengerComplete,
  action: PayloadAction<{
    category: PassengersCount;
    attribute: PassengersAttributes;
    index: number;
  }>
) => {
  return unassignAttributeToPassenger(state, action.payload);
};

export interface AttributePassengerFields {
  adult_wheelchair: number;
  child_ages: number[];
  child_wheelchair: number;
  child_wheelchair_ages: number[];
  senior_ages: number[];
  senior_wheelchair: number;
  senior_wheelchair_ages: number[];
  adult: number;
  child: number;
  senior: number;
  adult_student: number;
  child_student: number;
  child_student_ages: number[];
  senior_student: number;
  senior_student_ages: number[];
  adult_wheelchair_checkboxes: number[];
  adult_student_checkboxes: number[];
}

export const unassignAttributeToPassenger = (
  state: PassengerComplete | AttributePassengerFields,
  {
    category,
    attribute,
    index
  }: {
    category: PassengersCount;
    attribute: PassengersAttributes;
    index: number;
  }
) => {
  const fields: Partial<AttributePassengerFields> = {};

  if (category === ADULT) {
    const [pax_attribute] = NO_AGES_FIELDS[category][attribute];
    fields[pax_attribute] = Math.max(state[pax_attribute] - 1, 0);

    if (
      pax_attribute === "adult_wheelchair" ||
      pax_attribute === "adult_student"
    ) {
      fields[`${pax_attribute}_checkboxes`] = [
        ...state[`${pax_attribute}_checkboxes`]
      ];

      const field_pax_attribute_checkboxes =
        fields[`${pax_attribute}_checkboxes`];
      if (field_pax_attribute_checkboxes) {
        field_pax_attribute_checkboxes[index] = -1;
      }
    }
  } else {
    const [pax_attribute, , pax_attribute_ages] =
      AGES_FIELDS[category][attribute];

    fields[pax_attribute_ages] = [...state[pax_attribute_ages]];

    const field_pax_attribute_ages = fields[pax_attribute_ages];
    if (field_pax_attribute_ages) {
      field_pax_attribute_ages[index] = -1;

      fields[pax_attribute] = field_pax_attribute_ages.filter(
        val => val > -1
      ).length;
    }
  }

  return { ...state, ...fields };
};
