// Missing values
export const EMPTY_STRING = "empty";
export const EMPTY_NUMBER = 0;
export const EMPTY_ARRAY = [];
export const NA_STRING = "n/a";
export const NA_NUMBER = -1;
export const NA_ARRAY = [];
export const NA_BOOLEAN = false;
// Search type
export const ONEWAY = "one-way";
export const ROUNDTRIP = "roundtrip";
// Direction
export const OUTBOUND = "outbound";
export const RETURN = "return";
// Results content
export const BOB = "BoB";
export const BOX = "BoX";
export const INTERCONNECTION = "interconnection";
export const NODEAL = "nodeal";
export const NOSUPPLY = "nosupply";
export const INTERCONNECTION_WITH_STATION_CHANGE =
  "interconnection_station_change";
export const INTERCONNECTION_SAME_STATION = "interconnection_same_station";
// Departure Status
export const DEPARTURE_STATUS_OUTAGE = "outage";
export const DEPARTURE_STATUS_SOLD_OUT = "sold-out";
export const DEPARTURE_STATUS_AVAILABLE = "available";

export enum RouteType {
  REGULAR = "regular",
  INTERCONNECTED = "interconnected",
  MIX = "mix"
}

export enum ResultsListType {
  EMPTY = "empty",
  REGULAR_ONLY = "regular_only",
  INTERCONNECTED_ONLY = "interconnected_only",
  MIX = "mix"
}

export enum PollingErrors {
  HAS_ADAPTER_ERRORS = "adapter",
  HAS_FILTERED_DEPARTURES = "filtered",
  HAS_NAPI_PROCESSING_ERRORS = "napi",
  HAS_NO_LOCATIONS = "no_route",
  HAS_NO_DEPARTURES = "no_departures"
}

export enum ContentType {
  BOB = "BoB",
  BOX = "BoX",
  EMPTY_STRING = "empty",
  INTERCONNECTION = "interconnection",
  NOSUPPLY = "nosupply"
}

/**
 * Should be extending PollingErrors instead of redefining the shared values
 * but Typescript does not allows it yet.
 *
 * Discussion: https://github.com/microsoft/TypeScript/issues/17592
 */
export enum SearchErrors {
  HAS_ADAPTER_ERRORS = "adapter",
  HAS_FILTERED_DEPARTURES = "filtered",
  HAS_NAPI_PROCESSING_ERRORS = "napi",
  HAS_NO_LOCATIONS = "no_route",
  HAS_NO_DEPARTURES = "no_departures",
  INVALID_SEARCH = "invalid_search",
  INCOMPLETE_ERROR_DATA = "incomplete_error_data"
}

export enum CacheState {
  Fresh = "cache_fresh",
  Miss = "cache_miss",
  Stale = "cache_stale"
}

export enum CityChanged {
  Both = "both",
  Origin = "origin",
  Destination = "destination"
}
