import { useMemo } from "react";

import { bindActionCreators } from "redux";

import { openModal, closeModal } from "@app/store/state/modals";

import { useAppDispatch } from "../../helpers/hooks";

const modalActions = {
  closeModal,
  openModal
} as const;

export const useModalActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(modalActions, dispatch), [dispatch]);
};
