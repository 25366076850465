import { TrackingAction } from "@app/tracking/constants/tracking-action";
import type { Tracker } from "@app/tracking/tracker";
import { AmplitudeEvent } from "@app/types/tracking/common";
import { ClickedQuickAccessMenuLink } from "@app/types/tracking/quick-access-menu";

export function initQuickMenuTracking(tracker: Tracker) {
  const menu_links = document.querySelectorAll("[data-access-menu-link]");

  menu_links.forEach(link => {
    link.addEventListener("click", () => {
      if ("href" in link && typeof link.href === "string") {
        const hash = new URL(link.href).hash;

        tracker.asyncTrack(clickedQuickAccessMenuLink(hash));
      }
    });
  });
}

function clickedQuickAccessMenuLink(
  hash: string
): AmplitudeEvent<ClickedQuickAccessMenuLink> {
  return {
    action: TrackingAction.ClickedQuickAccessMenuLink,
    details: { hash }
  };
}
