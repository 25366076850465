import { PayloadAction } from "@reduxjs/toolkit";

import { PassengerComplete } from "@app/types/search-types";

import {
  ADULT,
  AGES_FIELDS,
  NO_AGES_FIELDS,
  PassengersCount,
  PassengersAttributes
} from "../passengers-constants";

export const assignAttributeToPassengerReducer = (
  state: PassengerComplete,
  action: PayloadAction<{
    category: PassengersCount;
    attribute: PassengersAttributes;
    index: number;
  }>
) => {
  const { category, attribute, index } = action.payload;
  const fields: Record<string, number[] | number> = {};

  if (category === ADULT) {
    const [pax_attribute] = NO_AGES_FIELDS[category][attribute];
    if (
      pax_attribute !== "child_student" &&
      pax_attribute !== "senior_student" &&
      pax_attribute !== "child_wheelchair" &&
      pax_attribute !== "senior_wheelchair"
    ) {
      fields[pax_attribute] = Math.min(state[pax_attribute] + 1, state[ADULT]);

      const pax_attribute_checkbox_values = [
        ...state[`${pax_attribute}_checkboxes`]
      ];
      pax_attribute_checkbox_values[index] = 1;
      fields[`${pax_attribute}_checkboxes`] = pax_attribute_checkbox_values;
    }
  } else {
    const [pax_attribute_count, pax_ages, pax_attribute_ages] =
      AGES_FIELDS[category][attribute];
    const age_to_add = state[pax_ages][index];

    const pax_attribute_ages_values = [...state[pax_attribute_ages]];
    pax_attribute_ages_values[index] = age_to_add;
    fields[pax_attribute_ages] = pax_attribute_ages_values;
    fields[pax_attribute_count] = pax_attribute_ages_values.filter(
      (val: number) => val > -1
    ).length;
  }

  return {
    ...state,
    ...fields
  };
};
