// Delays displaying the modal to avoid flickering when appearing
const FALLBACK_MODAL_DISPLAY_DELAY_MS = 100;
// Delays hiding the modal to avoid flickering when being replaced
const FALLBACK_MODAL_DISAPPEAR_DELAY_MS = 500;
const FALLBACK_MODAL_DOM = {
  id: "placeholderModal",
  class_show: "placeholder-modal-show"
};

let modal_timeout: NodeJS.Timeout | null = null;

export const getPlaceholderModal = (): HTMLDivElement | null => {
  const placeholder_modal = document.querySelector<HTMLDivElement>(
    `#${FALLBACK_MODAL_DOM.id}`
  );
  return placeholder_modal;
};

export const showPlaceholderModal = (): void => {
  const placeholder_modal = getPlaceholderModal();

  if (placeholder_modal) {
    modal_timeout = setTimeout(() => {
      placeholder_modal.classList.add(FALLBACK_MODAL_DOM.class_show);
      attachCloseClickListener();
    }, FALLBACK_MODAL_DISPLAY_DELAY_MS);
  }
};

const isReactHydrated = (): boolean => {
  return !!window.is_page_hydrated;
};

export const showPlaceholderModalEarly = (): void => {
  if (!isReactHydrated()) {
    showPlaceholderModal();
  }
};

export const hidePlaceholderModal = (): void => {
  const placeholder_modal = getPlaceholderModal();

  if (placeholder_modal) {
    clearPlaceholderModalTimeout();
    modal_timeout = setTimeout(() => {
      placeholder_modal.classList.remove(FALLBACK_MODAL_DOM.class_show);
    }, FALLBACK_MODAL_DISAPPEAR_DELAY_MS);
    detachCloseClickListener();
  }
};

export const clearPlaceholderModal = (): void => {
  clearPlaceholderModalTimeout();
  const placeholder_modal = getPlaceholderModal();
  if (placeholder_modal) {
    placeholder_modal.classList.remove(FALLBACK_MODAL_DOM.class_show);
    detachCloseClickListener();
  }
};

export const clearPlaceholderModalTimeout = (): void => {
  if (modal_timeout) {
    clearTimeout(modal_timeout);
    modal_timeout = null;
  }
};

export const attachCloseClickListener = (): void => {
  const placeholder_modal = getPlaceholderModal();

  if (placeholder_modal) {
    placeholder_modal.addEventListener("click", hidePlaceholderModal);
  }
};

export const detachCloseClickListener = (): void => {
  const placeholder_modal = getPlaceholderModal();

  if (placeholder_modal) {
    placeholder_modal.removeEventListener("click", hidePlaceholderModal);
  }
};
